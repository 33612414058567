<template>
    <div>
        <div>
            <h1 class="heading">PWA statistics</h1>
            <ObjectFilter
                    :object_id="$store.state.statistics.object_id"
                    :objects="objects.list"
                    @changeObject="changeObject"
                    @changeDates="changeDates"
                    @reloadData="reloadData"
            />
        </div>
        <div>
            <div class="statistics">
                <div class="statistics__statistics" :style="`width:${isMobile?'100%':'calc(66.66% + 20px)'};`">
                    <div class="statistics__chart" style="margin-top:0;">
                        <Chart
                  style="height:415px"
                  :height="415"
                                :data="chart_data"
                                :labels="dateArr"
                                :pointRadius="4"
                                :xAxes_ticks="true"
                        />
                    </div>
                </div>
                <div class="statistics__info" v-if="!isMobile">
                    <h3 class="statictics__caption">
                        {{ $t('statistics["Количество посещений"]') }}
                    </h3>
                    <p class="statistics__period">
                        <span>{{ all_visitors }}</span>
                        {{ $t('statistics["за текущий период"]') }}
                    </p>
                    <p class="statistics__total">
            <span
            >The graph shows the number of reviews left by your guests through
              the mobile app: {{ hotelName }}</span
            >
                    </p>
                </div>
            </div>
            <div style="margin-bottom: 15px">
                <v-data-table
                        class="table"
                        :headers="headers"
                        :items="pwaStatistics"
                        hide-default-footer
                        :items-per-page="999"
                >
                    <template v-slot:body="{ items }">
                        <tbody v-if="pwaStatistics.length">
                        <tr v-for="(item, i) in items" :key="i">
                            <td class="d-block d-sm-table-cell" style="cursor: pointer">
                                {{ item.day }}
                            </td>
                            <td class="d-block d-sm-table-cell">
                                {{ item.value }}
                            </td>
                        </tr>
                        </tbody>
                        <tbody v-else>
                        <tr>
                            <td
                                    class="d-block d-sm-table-cell"
                                    colspan="2"
                                    style="
                    text-align: center;
                    padding: 0 15px;
                    font-weight: normal;
                  "
                            >
                                Sorry no data for you
                            </td>
                        </tr>
                        </tbody>
                    </template>
                </v-data-table>
            </div>
        </div>
    </div>
</template>

<script>
    import titleMixin from "../../../mixins/titleMixin";
    import store from "@/store"
    import {mapState} from "vuex";
    import PWAService from "../../../services/pwa.service";
  export default {
    name: "PWAStatistics",
    mixins:[titleMixin],
    title() {
      return "PWA Statistics";
    },
    beforeRouteEnter(to, from, next) {
      if (!store.state.objects.list.length) {
        store
          .dispatch("objects/getList")
          .then((data) => {
            next();
          })
          .catch(() => {
            next();
          });
      } else {
        next();
      }
    },
    computed:{
      ...mapState(["objects"]),
    },
    data(){
      return{
        isMobile:false,
        chart_data:[],
        dateArr:[],
        all_visitors:0,
        hotelName:"",
        headers:[
          { text: "Date", value: "day" },
          { text: "Count", value: "value" },
        ],
        pwaStatistics:[]
      }
    },
    components:{
      ObjectFilter: () => import('@/components/ObjectFilter'),
      Chart: () => import('@/components/Chart')
    },
    created() {
      this.isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    },
    methods:{
      formatDateForTable(date) {
        if (date.includes("-")) {
          const dateArr = date.split("-");
          return `${dateArr[2]}.${dateArr[1]}.${dateArr[0]}`;
        } else {
          return date;
        }
      },
      formatDateForStatistics(date) {
        const dateArr = date.split(".");
        return `${dateArr[2]}-${dateArr[1]}-${dateArr[0]}`;
      },
      formatDateForChart(date) {
        if (date.includes("-")) {
          const dateArr = date.split("-");
          return `${dateArr[2]}.${dateArr[1]}`;
        } else {
          return date;
        }
      },
      async getStatistics(){
        const payload = {
          hotel_id: this.$store.state.statistics.object_id,
          date_start: this.formatDateForStatistics(
            this.$store.state.statistics.date_start
          ),
          date_end: this.formatDateForStatistics(
            this.$store.state.statistics.date_end
          )
        };
        const statistic = await PWAService.getPwaStatistcs(payload);
        this.dateArr = [];
        this.chart_data = [];
        this.pwaStatistics = [];
        this.all_visitors = 0;
        for(let date in statistic){
          this.dateArr.push(this.formatDateForChart(date));
          if(statistic[date]){
            this.chart_data.push(statistic[date]);
            this.all_visitors = this.all_visitors + statistic[date];
          }else{
            this.chart_data.push(0);
          }
          const tableItem = {
            day:this.formatDateForTable(date),
            value:statistic[date]
          }
          this.pwaStatistics.push(tableItem);
        }
      },
      async changeObject(id) {
        this.$store.state.statistics.object_id = id;
        if (
          !this.$store.state.statistics.date_start &&
          !this.$store.state.statistics.date_end
        ) {
          let end = this.$moment().format("YYYY-MM-DD");
          let start = this.$moment().subtract(30, "days").format("YYYY-MM-DD");
          this.$store.state.statistics.date_start =
            this.$moment(start).format("DD.MM.YYYY");
          this.$store.state.statistics.date_end =
            this.$moment(end).format("DD.MM.YYYY");
        }
        this.hotelName = this.objects.list.find((x) => x.id === id).name;
        await this.getStatistics();
      },
      async reloadData() {
        await this.getStatistics();
      },
      async changeDates(dates) {
        this.labels = [];
        if (dates.date_start && dates.date_end) {
          this.$store.state.statistics.date_start = this.$moment
            .unix(dates.date_start)
            .format("DD.MM.YYYY");
          this.$store.state.statistics.date_end = this.$moment
            .unix(dates.date_end)
            .format("DD.MM.YYYY");
        } else {
          this.$store.state.statistics.date_start = dates.date_start;
          this.$store.state.statistics.date_end = dates.date_end;
        }
        await this.getStatistics();
      },
    }
  }
</script>

<style lang="scss">
    .statistics {
        @include box;
        display: flex;
        // align-items: flex-end;
        margin-bottom: 30px;

        canvas {
            width: 100% !important;
        }
    }

    .statistics__statistics {
        position: relative;
        display: flex;
        flex-direction: column;
        width: calc(66.66% + 20px);
    }

    .statistics__type {
        position: absolute;
        width: 320px;
        top: 30px;
        left: 60px;
    }

    .statistics__chart {
        margin-top: auto;
    }

    .statistics__info {
        display: flex;
        flex-direction: column;
        width: calc(33.33% - 20px);
    }

    .statictics__caption {
        font-size: 16px;
        font-weight: 600;
        padding: 40px 40px 0;
    }

    .statistics__period {
        margin-bottom: auto;
        padding: 40px;
        font-weight: 500;
        font-size: 14px;
        color: #7f8385;
        span {
            display: block;
            margin-bottom: 5px;
            font-weight: bold;
            font-size: 80px;
            line-height: 1.21;
            color: #007db5;
            white-space: nowrap;
        }
    }

    .statistics__total {
        margin-top: auto;
        padding: 40px;
        border-radius: 0 0 5px 0;
        background: rgb(190, 193, 194);
        font-weight: 500;
        font-size: 14px;
        color: #fff;
        span {
            display: block;
            margin-bottom: 5px;
            font-weight: bold;
            font-size: 14px;
            line-height: 1.22;
        }
    }

    .statistics__icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 24px;
        height: 24px;
    }
    .statistics canvas{
      height: 415px !important;
    }
</style>
